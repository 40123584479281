import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
      <nav className="navbar is-primary">
          <div className="container">
              <div className="navbar-brand">
                  <a className="navbar-item" href="#">
                      { siteTitle }
                  </a>
                  <span className="navbar-burger burger" data-target="navMenu">
            <span></span>
            <span></span>
            <span></span>
          </span>
              </div>

          </div>
      </nav>
  </header>

);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
